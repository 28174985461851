<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Layout Views"
      subtitle="Browse and Manage your layout views!"
      :badge="this.total"
    />
    <v-row class="mt-0 mb-1 justify-space-between">
      <v-col>
        <v-btn color="info" @click="add"><i class="fal fa-plus mr-2"></i>Create Layout View</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="8">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      @click:row="rowClicked"
      class="elevation-2 layout-views-table table-sticky-header-exclude-footer"
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h4>{{ item.name }}</h4>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="update(item.id)" v-if="$has(perms.LayoutViews.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="del(item.id)" v-if="$has(perms.LayoutViews.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.LayoutViews.Update) && !$has(perms.LayoutViews.Delete)"
            >
              No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <edit-layout-view
      ref="editLayoutView"
      @save="onLayoutViewSaved"
      @close="onLayoutViewClosed"
    ></edit-layout-view>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import ApiService from "../services/layout-views-service.js";
import EditLayoutView from "../components/EditLayoutView.vue";
import layoutViewHeader from "../config/tables/layoutView.header";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import { UserFilterSettings } from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: { EditLayoutView, FilterManager },
  data() {
    return {
      perms: perms,
      viewsTimerId: null,
      storageKey: "layout-views",
      selectedFilters: [],
      paramId: null,
      openLayoutViewInEditMode: null,
      total: 0,
      entities: [],
      selected: {},
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        createdByIds: [],
        updatedByIds: [],
        itemsPerPage: -1,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: layoutViewHeader,
    };
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    // if (this.storageKey) {
    //   this.options.itemsPerPage = +this.$getFromLocal(
    //     `${this.storageKey}-itemsPerPage`,
    //     false,
    //     this.options.itemsPerPage || 15
    //   );
    // }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    //this.$refs.mainSearch.focus();
    this.checkRouteQuery();
    this.checkForSingleLayoutViewRoute(this.$route);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      this.selectedFilters = [createdByFilterSettings, updatedByFilterSettings];
    },
    checkRouteQuery(evt) {
      // this.$log("checkRoute", this.$route.query.q, evt);
      if (this.$route.query.q) this.options.search = this.$route.query.q;
      else this.options.search = null;
    },
    updateRouterLink() {
      if (this.$route.query.q == this.options.search) return;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          path: "/layout-views",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/layout-views", replace: true });
    },
    getData() {
      this.$backToTop(0, document.querySelector(".layout-views-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.$handleError(err, message);
          this.loadingStates.table = false;
        });
    },
    add() {
      this.selected = {};
      this.$refs.editLayoutView.open(null);
    },
    update(id) {
      this.selected = { id: id };
      this.openLayoutViewInEditMode = true;
      setTimeout(() => {
        const path = `/layout-views/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    view(id) {
      this.$log(">>>>>>> view", id);
      this.openLayoutViewInEditMode = false;
      setTimeout(() => {
        const path = `/layout-views/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    openLayoutView(id) {
      this.$log(">>>>>>> openLayoutView", id);
      // this.$log("openLayoutView =>>>", id, this.openLayoutViewInEditMode);
      setTimeout(() => {
        this.$refs.editLayoutView.open(id, this.openLayoutViewInEditMode);
        this.openLayoutViewInEditMode = false;
      }, 50);
    },
    rowClicked(row) {
      this.view(row.id);
    },
    del(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this layout view?`,
          title: `Delete Layout View?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onLayoutViewClosed() {
      const path = `/layout-views`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    onLayoutViewSaved(layoutViewData, isNewLayoutView) {
      this.$log("onLayoutViewSaved", layoutViewData, isNewLayoutView);
      if (isNewLayoutView) {
        this.addToArr(this.entities, layoutViewData, true);
      } else {
        this.updateArr(this.entities, layoutViewData);
      }
    },
    getDataDebounced() {
      if (this.viewsTimerId == null) {
        this.viewsTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.viewsTimerId);

      // delay new call 400ms
      this.viewsTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    checkForSingleLayoutViewRoute(route) {
      if (route.params && route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.openLayoutView(this.paramId);
      } else this.paramId = null;
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.checkRouteQuery();

        this.$log(">>>>>>> $route route");
        //from layout-views list view TO => single layout-views view,
        if (oldRoute.name == "layout-views" && newRoute.name == "single-layout-view") {
          this.$log(">>>>>>> $route m => s");
          this.openLayoutView(newRoute.params.id);
          return;
        }

        //from single layout-views view TO => single layout-views view,
        if (oldRoute.name == "single-layout-view" && newRoute.name == "single-layout-view") {
          this.$log(">>>>>>> $route s => s");
          this.openLayoutView(newRoute.params.id);
          return;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.layout-views-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
